@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Days+One&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Days+One&family=Oswald:wght@200..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Oxygen");
@import url("https://fonts.googleapis.com/css?family=Ubuntu");
@import url(https://fonts.googleapis.com/css?family=Asap:400,700);
@import url(https://fonts.googleapis.com/css?family=Bree+Serif&subset=latin-ext,latin);


body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    background-color: #1d1d1d;
    padding-bottom: 120px !important;
}

form {
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 0px 40px 1px #1b2a58;
    background-color: rgba(255, 255, 255, 0.305);
    width: 275px;
    overflow: hidden;
}

p {
    font-size: 17px;
}

a {
    color: #4369dc;
}

input {
    font-size: 1.2rem;
    border-radius: 10px;
    background-color: #ffffff9a;
    padding: 15px;
    border: none !important;
    margin-top: 10px;
}

input:focus-visible {
    outline: none !important;
}

.background-img {
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    width: 60vw;
    z-index: -1;
    left: 0;
    bottom: 0;
}

@media (max-width: 900px) {
    .background-img {
        width: 100vw;
    }
}
.phone-container {
    position: absolute;
    width: 200px;
    height: 400px;
    background-color: #000;
    border-radius: 20px;
    margin: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 99999;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .side-button {
    position: absolute;
    right: -10px;
    top: 100px;
    width: 10px;
    height: 60px;
    background-color: #555;
    border-radius: 5px;
    animation: click-animation 1.5s infinite;
  }
  
  @keyframes click-animation {
    0%, 100% { transform: translateX(0); }
    50% { transform: translateX(5px); }
  }
  
  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 16px;
    text-align: center;
  }
  

.dob {
    margin-left: -10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.dob input {
    width: 51px;
}

.fullscreen {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.spin {
    animation: spin 0.8s linear infinite; /* Spin animation */
    position: fixed;
    left: 50%; /* Center horizontally */
    top: 50%; /* Center vertically */
    margin-left: -50px; /* Offset by half width */
    margin-top: -50px; /* Offset by half height */
    z-index: 1000; /* Ensure it's on top of other elements */
}

.overlay {
    position: fixed; /* Fixed position to cover the entire viewport */
    top: 0;
    z-index: 9999;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.503); /* Semi-transparent gray background */
    z-index: 999; /* Ensure it's below the loader */
}

.overlay-2{
    position: fixed; /* Fixed position to cover the entire viewport */
    top: 0;
    z-index: 999;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.503); /* Semi-transparent gray background */
    z-index: 999; /* Ensure it's below the loader */

}

.spin {
    display: inline-block;
    z-index: 99999;
    width: 50px;
    height: 50px;
    border: 10px solid #e959ff;
    border-radius: 50%;
    border-top-color: #4369dc;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

.error {
    color: red;
    font-size: 15px;
    margin-top: 10px;
    text-align: center;
}

.overlay div {
    background-color: rgba(23, 23, 23, 0.623);
    max-width: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 10px;
    margin-left: -250px;
    border-radius: 10px;
    margin-top: -150px;
}

.overlay h3 {
    font-size: 25px;
}

.overlay a {
    width: 100%;
    display: block;
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Helvetica Neue",
        Arial, sans-serif;
    font-weight: 600;
    padding: 0;
    color: rgb(191, 191, 191);
    background-color: #1d1d1d;
}

.event h1,
.event h2,
.event h3,
.event h4,
.event h5,
.event h6,
.event p {
    cursor: text;
}

#gender {
    margin-left: 10px;
}

#gender option {
    background-color: #1d1d1d;
    color: #ffffff;
}

.checkbox{
   color: #000000;
}

.header-txt {
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    color: #000000;
    font-size: 3em;
    text-shadow: -3px 2px 5px white;
}

.Header-image div {
    width: 99vw;
    overflow: hidden;
    height: 30vh;
    justify-content: center;
    display: flex;
}

.Header-image img {
    width: 100%;
    height: max-content;
    transform: translateY(-50%);
}

.uje {
    display: flex;
    flex-wrap: wrap;
}

.eventbar {
    margin: 20px;
    width: clamp(280px, 46vw, 900px);
    min-width: 280px;
}

.header-box {
    padding: 10px;
    box-shadow: 0 0 2px;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
}

.event-data{
    max-width: 1000px;
    width: 1000px;
}

.box-home {
    display: flex;
    border-radius: 10px;
    width: 27%;
    justify-content: center;
    cursor: pointer;
    height: 90px;
    margin: 20px;
    align-items: center;
    box-shadow: 0 0 2px;
    padding: 15px;
    transition: 250ms;
}

.box-home:hover {
    box-shadow: 0 0 4px #f1f1f1;
}

.event {
    padding: 10px;
    box-shadow: 0 0 2px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.toggleContainer {
    position: relative;
    display: none;
    grid-template-columns: repeat(2, 1fr);
    width: fit-content;
    border: 3px solid #343434;
    border-radius: 20px;
    background: #343434;
    margin: 15px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
}
.toggleContainer::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0%;
    border-radius: 20px;
    background: white;
    transition: all 0.3s;
}
.toggleCheckbox:checked + .toggleContainer::before {
    left: 50%;
}
.toggleContainer div {
    padding: 6px;
    text-align: center;
    z-index: 1;
}
.toggleCheckbox {
    display: none;
}
.toggleCheckbox:checked + .toggleContainer div:first-child {
    color: white;
    transition: color 0.3s;
}
.toggleCheckbox:checked + .toggleContainer div:last-child {
    color: #000000;
    transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:first-child {
    color: #000000;
    transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:last-child {
    color: white;
    transition: color 0.3s;
}

@media (max-width: 1125px) {
    .box-home {
        flex-grow: 1;
    }
}

@media (max-width: 999px) {
    .eventbar {
        flex-grow: 1; /* Make the box take the full container width */
    }

    .toggleContainer {
        display: grid;
    }
}

@media (max-width: 678px) {
    .box-home {
        width: 100%;
    }
}

a {
    color: rgb(191, 191, 191);
    text-decoration: none;
}
@media (min-width: 900px) {
    .bottom-nav {
        display: none;
    }
}

@media (max-width: 900px) {
    .header-box {
        display: none;
    }
    .bottom-nav {
        display: flex;
        justify-content: space-around;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #1d1d1d;
        padding: 10px 0;
        box-shadow: 0 0 2px #000000;
        z-index: 99999;
    }
    .bottom-nav-item a{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
    .bottom-nav-item p{
        margin: 5px;
        font-size: 12px;
    }

    .bottom-nav-item-box {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
    }
}

@media (max-width: 382px){
    .bottom-nav-item p{
        font-size: 10px;
    }
}

@media (max-width: 335px){
    .bottom-nav-item p{
        display: none;
    }
}

/*-----------------------------------------------ACCOUNT-------------------------------------------*/


.left-nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    font-size: large;
    background-color: #1d1d1d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    box-shadow: 0 0 2px #000000;
    z-index: 100;
}

.left-nav-bar img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.left-nav-bar ul {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.left-nav-bar li {
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: 100ms;
}

.left-nav-bar li:hover {
    background-color: #2d2d2d;
}

.account-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.account-details-left p:has(input) {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.logout-btn {
    width: 100%;
    padding: 10px 0;
    border: none;
    background-color: rgba(255, 0, 0, 0.169);
    margin-top: 20px;
    cursor: pointer;
    transition: 100ms;
}

.account-details p {
    font-size: 15px;
}

.account-details span {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin-top: 5px;
}

.logout-btn:hover {
    background-color: rgba(255, 0, 0, 0.212) !important;
}

.left-nav-bar a {
    width: 100%;
    display: block;
    padding: 10px 0;
}

@media (max-width: 900px) {
    .left-nav-bar {
        display: none;
    }
    .menu-btn {
        display: block;
    }
    .menu-btn__burger {
        position: fixed;
        top: 5px;
        left: 5px;
        width: 35px;
        padding: 5px;
        box-shadow: 0 0 6px #000000;
        z-index: 100000;
    }

    .menu-btn__burger__patty {
        background-color: #ffffff;
        height: 3px;
        width: 25px;
        margin: 5px;
        border-radius: 5px;
    }
    .left-nav-bar--active {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        width: 250px;
        height: 100vh;
        font-size: large;
        background-color: #1d1d1d;
        padding: 20px 0;
        box-shadow: 0 0 2px #000000;
        z-index: 100;
    }
    
    
}

.eventView {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: #1d1d1de7;
    border-radius: 20px;
    top: 50%;
    margin-top: 50px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1000px;
    z-index: 99999999;
    position: absolute;
}

.eventView p {
    font-size: 20px;
    color: #ffffff;
}

.evn-view-info {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-left: 20px;
}

@media (max-width: 1100px) {
    .eventView {
        margin-left: 40px;
        margin-right: 40px;
    }
}

/*-----------------------------------------------Parent link confirm-------------------------------------------*/

.plc-main-c {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    flex-direction: column;
}
.plc-h1 {
    font-size: 40px;
    color: #035ec5;
    text-align: center;
}

.PLC-P {
    font-size: 15px;
    color: #e4e0e0;
    margin-top: 5px;
    margin-bottom: 100px;
    text-align: center;
}

.plc-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    flex-direction: column;
    background-color: rgb(19, 18, 18);
    width: 45vw;
    border-radius: 30px;
    background: linear-gradient(#212121, #212121) padding-box, linear-gradient(145deg, #e81cff, #40c9ff) border-box;
    border: 2px solid transparent;
}
.plc-button {
    font-size: 25px;
}

@media (max-width: 1200px) {
    .plc-h1 {
        font-size: 26px;
    }
}

@media (max-width: 650px) {
    .plc-h1 {
        font-size: 25px;
    }
    .plc-container {
        width: 90vw;
    }
}

@media (max-width: 380px) {
    .plc-h1 {
        font-size: 20px;
    }
}

/*-----------------------------------------------ADMIN-------------------------------------------*/

.card {
    background-color: #1d1d1d;
    border-radius: 10px;
    box-shadow: 0 0 2px #000000;
    padding: 20px;
    margin: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 50%;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

@media (max-width: 450px) {
    .card h2 {
        font-size: 20px;
    }
}

.boxca {
    display: flex;
    flex-wrap: wrap;
}

.boxa {
    padding: 15px;
    margin: 15px;
    box-shadow: 0 0 3px #767676;
    border-radius: 15px;
    cursor: pointer;
    transition: 300ms;
}

.boxa:hover {
    box-shadow: 0 0 2px #ffffff;
}

.aeb {
    width: fit-content !important;
}

.ae {
    width: 99%;
    margin-right: 10px;
}

.optional-text {
    font-size: 12px;
    color: #797979;
    margin-bottom: 2px !important;
}

button {
    font-size: 15px;
    border-width: 0px;
    padding: 10px;
    color: white;
    background-color: rgb(97, 0, 111);
    font-weight: 300;
    font-family: "Roboto";
    border-radius: 5px;
    margin-right: 10px;
    transition: 0.4s;
    cursor: pointer;
    border: 1px solid rgb(117, 1, 135);
    margin-bottom: 10px;
}

button:hover {
    color: rgb(233, 89, 255);
    background-color: #1d1d1d;
}

.adh1 {
    text-align: center;
}

.create-event {
    border-radius: 15px;
    box-shadow: 0 0 3px #767676;
    margin: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    width: 45vw;
    overflow: auto;
}

.create-event label {
    display: block;
    margin-bottom: 5px;
    color: #717171;
    font-weight: 600;
    font-size: 15px;
}

input {
    padding: 12px 16px;
    border-radius: 8px;
    color: #8c8c8c;
    background-color: transparent;
    border: 1px solid #ffffff !important;
}

textarea {
    width: 95%;
    padding: 12px 16px;
    border-radius: 8px;
    resize: none;
    color: #fff;
    border: 1px solid #414141;
    background-color: transparent;
    font-family: inherit;
}

.create-event input:focus {
    outline: none;
    border-color: #e81cff !important;
}

textarea:focus {
    outline: none;
    border-color: #e81cff;
}

.event-preview {
    width: 45vw;
    border-radius: 15px;
    border: 2px solid transparent;
    padding: 10px;
    margin-left: 20px;
    background: linear-gradient(#212121, #212121) padding-box, linear-gradient(145deg, #e81cff, #40c9ff) border-box;
}

form {
    background: linear-gradient(#212121, #212121) padding-box, linear-gradient(145deg, #e81cff, #40c9ff) border-box;
    border: 2px solid transparent;
    font-size: 14px;
    font-family: inherit;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 16px;
}

.create-event form {
    padding: 32px 24px;
    font-size: 14px;
    font-family: inherit;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    border-radius: 16px;
}

.ar-map-button {
    width: 42px;
    height: 42px;
    background-color: #1d1d1d;
    border: 1px solid green;
    margin-right: 10px;
    border-radius: 21px;
}

.add:hover {
    background-color: green;
}

.remove:hover {
    background-color: rgb(164, 0, 0) !important;
}

.add:hover path {
    fill: #1d1d1d;
}

.remove:hover path {
    fill: #1d1d1d;
}

.race-map-select {
    display: flex;
    gap: 10px;
    min-width: 200px;
    align-items: center;
}

select {
    padding: 5px;
    border-radius: 8px;
    color: #8c8c8c;
    font-size: 20px;
    border: 1px solid #ffffff !important;
}

.wrap {
    min-width: 562px;
    background: #00000000;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.4);
    padding: 10px;
}

.toolbar {
    width: 100%;
    margin: 0 auto 10px;
}

.wrap button {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    background: none;
    border: none;
    box-sizing: border-box;
    padding: 0;
    font-size: 20px;
    color: #a6a6a6;
    cursor: pointer;
    outline: none;
}

.date-ad{
    display: flex;
    gap: 10px;
}

.date-ad input {
    max-width: 60px;
}

.wrap button:hover {
    border: 1px solid #a6a6a6;
    color: #777;
}

.wrap input {
    color: #000000;
}

#bold,
#italic,
#underline {
    font-size: 18px;
}

.wrap #underline,
.wrap #align-right {
    margin-right: 17px;
}

.wrap #align-left {
    margin-left: 17px;
}

.wrap select {
    font-size: 15px;
    font-weight: bold;
    color: #444;
    background: #fcfcfc;
    border: 1px solid #a6a6a6;
    border-radius: 3px;
    margin: 0;
    outline: none;
    cursor: pointer;
}

select > option {
    font-size: 15px;
    background: #fafafa;
}

.newPost {
    width: 100%;
    max-width: 800px;
    min-width: 480px;
    font-family: 'Asap', sans-serif;
  }

  .newPost h3 {
    margin: 0px 0px 20px 0px;
    font-size: 30px;
    color: #2E3641;
  }

  .newPost input[type="text"] {
    padding-left: 15px;
    width: 100%;
    height: 45px;
    border: 1px solid #444444;
    box-shadow: inset 0px 0px 3px #3d3d3d;
    outline: none;
    font-family: 'Bree Serif', serif;
    font-size: 24px;
    color: #212121;
    margin-bottom: 20px;
  }

  .newPost .toolbar {
    background: #2E3641;
  }

  .newPost .toolbar button {
    float: left;
    width: 45px;
    height: 45px;
    background: #2E3641;
    border: none;
    border-right: 1px solid #434f5f;
    color: #C1CFD9;
    outline: none;
  }

  .newPost .toolbar button:hover {
    background: #394250;
    color: #b1c3cf;
  }

  .newPost .toolbar .customSelect {
    float: right;
    position: relative;
  }

  .newPost .toolbar .customSelect select {
    appearance: none;
    border-radius: 0;
    border: none;
    background: #2E3641;
    height: 45px;
    padding-left: 10px;
    padding-right: 25px;
    color: #C1CFD9;
    border-left: 1px solid #434f5f;
    outline: none;
    font-weight: bold;
    cursor: pointer;
  }
  .newPost .toolbar .customSelect select:hover {
    background: #394250;
    color: #b1c3cf;
  }
  .newPost .toolbar .customSelect:after {
    content: "\f0dc";
    font-family: FontAwesome;
    color: #C1CFD9;
    position: absolute;
    right: 10px;
    top: 15px;
  }
  .newPost .toolbar:after {
    content: '';
    display: block;
    clear: both;
  }
  .newPost .editor {
    min-height: 500px;
    width: 100%;
    resize: none;
    border: 1px solid #000000;
    box-shadow: inset 0px 0px 3px #000000;
    outline: none;
    padding: 15px;
    margin-bottom: 20px;
    position: relative;
  }
  .newPost .editor .saved {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: #FFF;
    text-transform: uppercase;
    background: #C1CFD9;
    font-weight: bold;
    border-top-left-radius: 15px;
  }
  .newPost .buttons {
    float: right;
  }
  .newPost .buttons button {
    float: left;
    width: 120px;
    height: 45px;
    border: none;
    color: #FFF;
    text-transform: uppercase;
    outline: none;
    margin-right: 20px;
    font-weight: bold;
    background: #C1CFD9;
    text-decoration: none;
  }
  .newPost .buttons button:last-of-type {
    margin-right: 0px;
    background: #ff4b4b;
  }
  .newPost .buttons button:last-of-type:hover {
    background: #ff2c2c;
  }
  .newPost .buttons button:active {
    box-shadow: inset 0px 4px rgba(0, 0, 0, 0.05);
  }
  .newPost .buttons button:hover {
    background: #d1dbe3;
  }
  .newPost .buttons:after {
    content: '';
    display: block;
    clear: both;
  }
  .newPost:after {
    content: '';
    display: block;
    clear: both;
  }
  @media (max-width:650px) {
    .newPost {
      padding: 15px 10px;
      width: 100%;
      min-width: 0;
    }
  }
.member-info-box {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    padding: 30px;
}

.member-info-box .box {
    gap: 10px;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.799);
}

.participants-list{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.participants{
    gap: 10px;
    flex-wrap: wrap;
    position: relative;
    z-index: 99999999;
    background-color: #00000073;
    border-radius: 10px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.799);
    padding: 10px;
    margin: 10px;
    width: fit-content;
}

.z-index{
    z-index: 99999999;
    position: relative;
}

@media (max-width: 1000px) {
    .create-event-page {
        flex-wrap: wrap;
    }
    .create-event, .event-preview {
        width: 90vw;
    }
}

.convert-overlay{
    background-color: rgba(23, 23, 23, 0.623);
    max-width: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 30px;
    margin-left: -175px;
    border-radius: 10px;
    margin-top: -350px;
}

.convert-overlay input{
    margin: 10px;
    width: calc(100% - 54px);
}

.convert-overlay button{
    margin: 10px;
    width: calc(100% - 22px);
}

.slide-down {
    opacity: 0;
    transform: translateY(-20px);
    animation: slideDown 0.5s forwards;
    -webkit-animation: slideDown 0.5s forwards;
    -moz-animation: slideDown 0.5s forwards;
    -o-animation: slideDown 0.5s forwards;
    -ms-animation: slideDown 0.5s forwards;
}

@keyframes slideDown {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes slideDown {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slideDown {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes slideDown {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes slideDown {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-up {
    opacity: 1;
    transform: translateY(0);
    animation: slideUp 0.5s forwards;
    -webkit-animation: slideUp 0.5s forwards;
    -moz-animation: slideUp 0.5s forwards;
    -o-animation: slideUp 0.5s forwards;
    -ms-animation: slideUp 0.5s forwards;
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-20px);
        opacity: 0;
    }
}

/* Firefox < 16 */

@-moz-keyframes slideUp {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-20px);
        opacity: 0;
    }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes slideUp {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-20px);
        opacity: 0;
    }
}

/* Internet Explorer */

@-ms-keyframes slideUp {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-20px);
        opacity: 0;
    }
}

/* Opera < 12.1 */

@-o-keyframes slideUp {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-20px);
        opacity: 0;
    }
}

